import _ from 'lodash'

export default {
    methods: {
        addToCart (newCart) {
            newCart.selected = false
            let cart = JSON.parse(localStorage.getItem('cart'))
            if (_.isEmpty(cart)) {
                cart = [newCart]
            } else {
                cart.push(newCart)
            }
            localStorage.setItem('cart', JSON.stringify(cart))
            this.$router.push({name: 'Cart'})

        }
    }
}
