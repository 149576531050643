<template>
  <div class="d-flex justify-space-between align-center">
    <div>
      <span>{{ size }} ({{ symbol }})</span>
    </div>
    <btn-incrementer v-model="count" />
  </div>
</template>

<script>
import BtnIncrementer from "@/reuseable/BtnIncrementer";
export default {
  name: "ShirtSizeButton",
  components: {BtnIncrementer},
  data: () => ({
    count: 0,
  }),
  props: {
    size: String,
  },
  computed: {
    symbol () {
      const symbols = {
        'Small': 'S',
        'Medium': 'M',
        'Extra': 'X',
        'Large': 'L'
      }
      return this.size.split(' ').map(i => symbols[i] ?? 'S').join('')
    }
  },
  watch: {
    count: {
      handler: function (count) {
        this.$emit('change', count)
      },
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>