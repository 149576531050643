<template>
  <div>
    <build-header @click="routeToCart" />
    <v-row>
      <v-col cols="12" md="6" v-if="!$vuetify.breakpoint.mobile && $route.query.request">
        <div>
          <v-img
            width="1000"
            :src="frontImageURL" />
        </div>
      </v-col>
      <v-col  cols="12" md="6" v-if="!$vuetify.breakpoint.mobile && !$route.query.request">
        <FrontMerch :json-style="frontDesign" v-if="!showBack"  :width="'270px'" :height="'350px'" :load-from-j-s-o-n="true"/>
        <BackMerch v-else  :json-style="backDesign"   :width="'270px'" :height="'350px'" :load-from-j-s-o-n="true"/>
      </v-col>
      <v-col cols="12" md="6">
        <div>
          <v-btn text :to="{ name: 'Build' }">
            <vue-feather type="arrow-left"/>
          </v-btn>
          <div class="px-6 my-8">
            <div>
              <div class="tw-flex tw-items-center">
                <h4 class="page--title">
                  QUANTITY
                </h4>
                <div v-if="!$vuetify.breakpoint.mobile" @click="showBack=!showBack" class="toggle__orientation__button ml-3">
                  <div class="flex-grow-1 ml-1 pa-2">
                    SHOW {{showBack?'FRONT':'BACK'}}

                  </div>
                </div>
              </div>

              <p class="page--description my-6">
                What size(s) would <br /> you like to purchase?
              </p>
            </div>
          </div>
          <div class="sizes--wrapper px-6">
            <div v-for="(size, i) in sizes" :key="i" class="my-6">
              <shirt-size-button :size="size.name" @change="item.sizes[size.symbol] = $event" />
            </div>
          </div>
          <div class="buttons--wrap d-flex tw-px-4">
            <div class="mr-4">
              <v-btn class="cancel__btn" rounded color="primary" outlined>
                Cancel
              </v-btn>
            </div>
            <div>
              <v-btn :loading="loading" class="add__to__cart white--text" @click="routeToCart" rounded depressed color="primary">
                Add To Cart
                <vue-feather type="arrow-right" />
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import BtnIncrementer from "@/reuseable/BtnIncrementer";
import ShirtSizeButton from "@/components/addToCart/ShirtSizeButton";
import BuildHeader from "@/components/build/BuildHeader";
import addToCart from "@/mixins/addToCart";
import priceMixin from "@/mixins/priceMixin";
import { v4 as uuid } from 'uuid'
import { addMerchToCart } from "@/services/auth-services";
import BackMerch from "@/components/build/BackMerch";
import FrontMerch from "@/components/build/FrontMerch";
import { uploadDocumentToCloudinary } from "@/services/helpers";

export default {
  name: "AddToCart",
  components: { FrontMerch, BackMerch, BuildHeader, ShirtSizeButton, BtnIncrementer},
  mixins: [addToCart,priceMixin],
  data: () => ({
    sizes: [
      {
        name: 'Small',
        symbol: 'S'
      },
      {
        name: 'Medium',
        symbol: 'M'
      },
      {
        name: 'Large',
        symbol: 'L'
      },
      {
        name: 'Extra Large',
        symbol: 'XL'
      },
      {
        name: 'Extra Extra Large',
        symbol: 'XXL'
      }
    ],
    item: {
      title: '',
      name: '',
      sizes: {
        S: 0,
        M: 0,
        L: 0,
        XL: 0,
        XXL: 0,
      },
      price: 2500,
      color: ''
    },
    loading:false,
    cloud_front_url:'',
    showBack:false,
    frontDesign:{},
    backDesign:{},
    frontImageURL: '',
    requestDesign: null
  }),
  created() {
    this.item.name = this.$route.query.productName
    this.item.title = this.$route.query.title
    this.item.id = uuid()
    if (!this.$route.query.request) {
      if(!this.canvasImageURL){
        this.$store.commit('buildMerch/SET_CANVAS_IMAGE_URL',localStorage.getItem('buildImg'))
      }
      this.item.shirt = this.selectedShirt
      this.item.color = this.selectedColor
      this.frontDesign = JSON.parse(localStorage.getItem('frontBuildJson'))
      this.backDesign = JSON.parse(localStorage.getItem('backBuildJson'))
    } else {
      this.frontImageURL = this.requestDesign.selected_product.front
    }
  },
  computed: {
    ...mapGetters('buildMerch', ['selectedShirt']),
    ...mapState('buildMerch', ['canvasImageURL', 'selectedColor','canvasJson', 'requestDesignDetails']),
    isLoggedIn(){
      return JSON.parse(localStorage.getItem('userToken')) || null
    }
  },
  methods: {
    increment (symbol) {

    },
    uploadToCloudinary(file){
      this.loading = true
      const fileData = new FormData();
      fileData.append('file', file);
      fileData.append('upload_preset', 'kneekpreset'); // upload preset
      fileData.append('tags', 'xxxxxx'); // optional

      fetch('https://api.cloudinary.com/v1_1/spacehubpictures/auto/upload', {
        method: 'post',
        body: fileData
      }).then(response => response.json())
        .then(data => {
            this.cloud_front_url = data.url
            console.log('Success:', data)
          this.routeToCart()
            this.loading = false
          }
          )
        .catch(err => console.error('Error:', err));
    },
    async routeToCart () {
      console.log('carter')
      if (Object.values(this.item.sizes).every(size => !size)) {
        return this.$displaySnackbar({message: "You haven't selected any size"})
      }
      let data = {}
      if (!this.$route.query.request) {
        data = this.generateBuildData()
      } else {
        const {selected_product: { id: selected_product }, merch_name, text, design_print} = this.requestDesignDetails
        const { url } = await uploadDocumentToCloudinary(design_print, 'requested-designs')
        data = {
          selected_product,
          merch_name,
          text,
          design_print: url,
          sizes:this.item.sizes,
          color: this.selectedColor
        }
      }
      if(this.isLoggedIn){
        this.addItemToCart(data)
      }
      else{
        this.addToCart(data )
      //to be removed later
      // this.addItemToCart(data)

      }
    },
    addItemToCart(data){
      this.loading = true
      addMerchToCart({
        merches:[data],
        source: !this.$route.query.request ? 'build_merch' : 'design_request'
      }).then(res=>{
        console.log(res)
        this.$router.push({name: 'Cart', query: {id: res.data[0].id}})
      })
      .catch(err=>{
        this.loading= false
        console.log(err)
      })
    },
    generateBuildData () {
      let designs = JSON.parse(localStorage.getItem('frontBuildJson'))
      let backDesigns = JSON.parse(localStorage.getItem('backBuildJson'))
      const AVAILABLE_PRODUCT = 3

      let numberOfSide
      let numberOfShirt = Object.values(this.item.sizes).reduce((acc, curr) => curr + acc, 0)
      let data = {
        selected_product: AVAILABLE_PRODUCT,

        merch_data: {
          back: backDesigns.back||null,
          merch_name: designs.title,
          front:designs.front,
          selectedShirt:designs.selectedShirt,
          // default: "true",
          color_code: this.selectedColor
        },

        sizes:this.item.sizes,
        // color: this.selectedColor
      }

      let design = JSON.parse(this.backDesign.back)
      console.log(numberOfShirt)
      // Object.keys(this.backDesign.objects).length? numberOfSide=2:numberOfSide=1
      // console.log(numberOfSide,this.backDesign)
      Object.keys(design.objects).length? numberOfSide=2:numberOfSide=1
      console.log(numberOfSide,design)
      data.price = this.calculatePrice(numberOfSide, numberOfShirt)
      return data
    }
  }
}
</script>

<style scoped lang="scss">
h4.page--title {
  color: #979797;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

p.page--description {
  line-height: 31px;
  font-size: 24px;
  font-weight: 500;
}

.sizes--wrapper {
  max-width: 400px;
}

.row {
  height: calc(100vh - 82px) !important;
  overflow: hidden;
}

.buttons--wrap {
  margin: 100px 0;
}
.toggle__orientation__button {
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  box-shadow: 0px 12px 24px rgba(232, 232, 232, 0.4);
  border-radius: 8px;
  //width: 168px;
  //min-height: 50px;
  //height: 55px;
  overflow-y: hidden;
  cursor: pointer;

  div.flex-grow-1 {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
  }
}
</style>
