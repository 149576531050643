<template>
  <div class="btn-incrementer d-flex">
    <div>
      <v-btn class="left" @click="decrement">
        <vue-feather size="18" type="minus"/>
      </v-btn>
    </div>
    <div class="count d-flex justify-center align-center">{{ count }}</div>
    <div>
      <v-btn class="right" @click="increment">
        <vue-feather size="18" type="plus"/>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "BtnIncrementer",
  data: () => ({
    count: 0
  }),
  props: {
    max: [Number, String]
  },
  created () {
    const { $attrs: { value } } = this
    if (+value) this.count = value
  },
  methods: {
    increment () {
      const { count, max } = this
      if (count !== max) {
        this.$emit('input', ++this.count)
      }
    },
    decrement () {
      const { count } = this
      if (count !== 0) {
        this.$emit('input', --this.count)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.btn-incrementer {
  width: 101px;
  border-radius: 4px;

  .v-btn {
    box-shadow: none !important;
    width: 32px !important;
    background: white;
    border: 2px solid #EAEAEA;
    height: 32px;
    min-width: 32px !important;

    &.left {
      border-radius: 4px 0px 0px 4px;
    }

    &.right {
      border-radius: 0px 4px 4px 0px;
    }
  }

  .count {
    height: 32px;
    width: 32px;
    border-top: 2px solid #EAEAEA;
    border-bottom: 2px solid #EAEAEA;
    font-weight: 500;
    font-size: 12px;
  }
}
</style>