import { render, staticRenderFns } from "./BtnIncrementer.vue?vue&type=template&id=24392d24&scoped=true&"
import script from "./BtnIncrementer.vue?vue&type=script&lang=js&"
export * from "./BtnIncrementer.vue?vue&type=script&lang=js&"
import style0 from "./BtnIncrementer.vue?vue&type=style&index=0&id=24392d24&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24392d24",
  null
  
)

export default component.exports